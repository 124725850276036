export function generateToken(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let token = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    token += characters.charAt(randomIndex);
  }
  return token;
}

export function removeCityFromFullName(
  cityName: string,
  fullName: string
): string {
  if (fullName.startsWith(cityName + ", ")) {
    return fullName.substring(cityName.length + 2);
  }
  return fullName;
}

export function validateBookingDates(checkInDate: Date) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const checkIn = new Date(checkInDate);

  if (checkIn <= yesterday) {
    return { valid: false, message: "checkInDatePastError" };
  }

  return { valid: true };
}

export function isGccCountry(countryCode: string): boolean {
  // List of GCC countries and their respective codes
  const gccCountries = ["SA", "AE", "KW", "QA", "OM", "BH"];

  // Check if the provided country code is in the list of GCC countries
  return gccCountries.includes(countryCode.toUpperCase());
}

export function clearLocalStorageOnce(): void {
  const clearKey = "IslocalStorageClearedLevel2025";
  if (typeof window !== "undefined") {
    // Check if the localStorage has been cleared before
    const clearedBefore = localStorage.getItem(clearKey);

    if (!clearedBefore) {
      // Clear all items in localStorage
      localStorage.clear();

      // Set the key to indicate that localStorage has been cleared
      localStorage.setItem(clearKey, "true");
    }
  }
}

export const hasReachedRegisterLimit = () => {
  const RATE_LIMIT_KEY = "registerNewUserRequests";
  const MAX_REQUESTS = 3;
  const TIME_WINDOW = 120 * 60 * 1000; // 2 hours in milliseconds

  const now = Date.now();
  const requestLog = JSON.parse(localStorage.getItem(RATE_LIMIT_KEY) || "[]");

  // Filter out requests older than 30 minutes
  const recentRequests = requestLog.filter(
    (timestamp: number) => now - timestamp < TIME_WINDOW
  );

  // Disable the button if the user has reached the maximum request limit
  return recentRequests.length >= MAX_REQUESTS;
};
