import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { ClipLoader } from "react-spinners";
import { useSearchQueryStore } from "store/useSearchQueryStore";
import { useUserStore } from "store/useUserStore";
import { getCitiesHotels } from "utils/api/search/getCitiesHotels";
import { getRecentSearch } from "utils/api/search/getRecentSearch";
import LocationSelect from "./locationSelect";
import RecentSearchSelect from "./recentSearchSelect";

function LocationCard() {
  const { locationQuery, setLocationQuery }: any = useSearchQueryStore();
  const { deviceToken, nationalityCode }: any = useUserStore();
  const [noAutoComplete, setNoAutoComplete] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  const [hotelsList, setHotelsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [recentSearchList, setRecentSearchList] = useState([]);
  const [showRecentSearch, setShowRecentSearch] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { pathname, locale } = useRouter();
  const [isError, setIsError] = useState({
    status: false,
    message: "",
  });
  const [count, setCount] = useState(0);
  const { t: translate, i18n } = useTranslation();

  const [locationInputState, setLocationInputState] = useState<any>("");

  const handleGetCitiesHotels = async (keyword: string) => {
    if (keyword === "" || keyword.length < 2) return;
    setIsLoading(true);
    const response = await getCitiesHotels(keyword, locale, nationalityCode);
    if (
      response.data &&
      Object.keys(response.data).length === 0 &&
      response.data
    ) {
      setIsError({
        status: true,
        message: locale === "en" ? "No results found" : "لا توجد نتائج",
      });
      setIsLoading(false);
      setCitiesList([]);
      setHotelsList([]);
      setShowLocations(false);
      return;
    }
    if (response.data) {
      setIsError({ status: false, message: "" });
      response.data.cities
        ? setCitiesList(response.data.cities)
        : setCitiesList([]);
      response.data.hotels
        ? setHotelsList(response.data.hotels)
        : setHotelsList([]);
    }
    setIsLoading(false);
  };

  const handleLocationChange = () => {
    handleGetCitiesHotels(locationInputState);
    setShowLocations(true);
  };

  //* Hide the list on clicking outside *//
  const locationDivRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        locationDivRef.current &&
        !locationDivRef.current.contains(event.target as Node)
      ) {
        setShowLocations(false);
        setShowRecentSearch(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showLocations, showRecentSearch]);

  useEffect(() => {
    const handleRecentSearch = async () => {
      const response = await getRecentSearch({ deviceToken });
      response?.data && setRecentSearchList(response.data);
    };
    handleRecentSearch();
    locationQuery.name && setLocationInputState(locationQuery.name);
  }, [locale]);

  useEffect(() => {
    if (locationQuery.name) setLocationInputState(locationQuery.name);
  }, [locationQuery.name]);

  useEffect(() => {
    if (locationInputState?.length < 2 || count === 0 || noAutoComplete) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const timer = setTimeout(() => {
      handleLocationChange();
      setIsDone(true);
    }, 500);
    return () => clearTimeout(timer);
  }, [locationInputState]);

  return (
    <div
      className={`flex-grow-0 lg:z-auto relative h-max bg-white shadow-lg shadow-neutral md:rounded-lg ${
        pathname === "/"
          ? "lg:rounded-full p-4"
          : "lg:rounded-3xl h-16 p-3 px-3"
      } `}
    >
      <div className="relative flex gap-2 ">
        <div className="opacity-50">
          <GrLocation className="opacity-50" size={24} />
        </div>
        <div className="w-10/12">
          <h1
            style={{ fontFamily: "BiennaleSemiBold" }}
            className="text-xs opacity-50"
          >
            {translate("searchBar.Where are you going?")}
          </h1>
          <form autoComplete="false" action="">
            {/* This is to prevent chrome from autofilling the input */}
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <input
              autoComplete="off"
              className="w-full p-0 mt-1 border-transparent focus:ring-0 focus:border-transparent input input-xs "
              name="city"
              value={locationInputState}
              type="text"
              placeholder={translate("searchBar.City or Hotel") as string}
              onChange={(e) => {
                e.target.value === ""
                  ? (setCount(0), setIsError({ status: false, message: "" }))
                  : (setShowRecentSearch(false), setCount(1));

                setNoAutoComplete(false);
                setLocationInputState(e.target.value);
              }}
              onFocus={() => {
                count === 0 || locationInputState === ""
                  ? setShowRecentSearch(true)
                  : "";
                if (locationInputState !== "" && !showRecentSearch)
                  setShowLocations(true);
              }}
            />
          </form>
          {isError.status && (
            <div
              className={`absolute text-xs text-error top-[5px] ${
                i18n.language === "ar" ? "left-[25px]" : "right-[25px]"
              }`}
            >
              {isError.message}
            </div>
          )}
          {isLoading && (
            <ClipLoader
              className={`absolute  top-5
              ${locale === "en" ? "right-5" : "left-5"}
              `}
              color="#0081DF"
              size={20}
            />
          )}
          {locationInputState !== "" ? (
            <AiOutlineCloseCircle
              onClick={() => {
                setLocationInputState("");
                setIsError({ status: false, message: "" });
                setIsDone(false);
              }}
              className={`absolute cursor-pointer top-6 hover:text-primary
          ${locale === "en" ? "right-0" : "left-0"}
          `}
            />
          ) : (
            " "
          )}
        </div>
      </div>
      {showRecentSearch && recentSearchList.length > 0 && (
        <div ref={locationDivRef}>
          <RecentSearchSelect
            setShowRecentSearch={setShowRecentSearch}
            recentSearchList={recentSearchList}
            setLocationQuery={setLocationQuery}
            setLocationInputState={setLocationInputState}
            setNoAutoComplete={setNoAutoComplete}
            setRecentSearchList={setRecentSearchList}
          />
        </div>
      )}
      <p className="text-center text-sm text-red-500 absolute left-12 top-[55px]">
        {isDone &&
          citiesList.length === 0 &&
          hotelsList.length === 0 &&
          locationInputState &&
          (locale === "ar" ? "لا يوجد نتائج" : "No Results Found")}
      </p>
      {showLocations && (citiesList.length > 0 || hotelsList.length > 0) && (
        <div ref={locationDivRef}>
          <LocationSelect
            cities={citiesList}
            hotels={hotelsList}
            setLocationInputState={setLocationInputState}
            setShowLocations={setShowLocations}
            setLocationQuery={setLocationQuery}
            setNoAutoComplete={setNoAutoComplete}
          />
        </div>
      )}
    </div>
  );
}

export default LocationCard;
