export const apiServices = {
  login: "api/login",
  register: "api/register",
  forgotPassword: "api/forgot-password",
  getCititesQuery: "api/citiesHotels/keyword?keyword=",
  getHotelChunks: `${process.env.NEXT_PUBLIC_NODE_BASE_URL}/get-chunks`,
  getHotelTypes: "/api/hotelTypes?lang=",
  getHotelAmenities: "/api/amenities",
  getHotelById: "/api/hotel_info/",
  getAvailableRooms: "/api/hotels",
  checkAvailability: "api/hotels/availability",
  initPayment: "/api/payments/1",
  getPublicKey: "/api/payments/1/getToken",
  confirmBooking: "/api/hotels/book",
  getGuestBooking: "/api/guest-reservations",
  validateCoupon: "/api/coupons/validate",
  reservationsList: "/api/hotel-reservations",
  cancelResrvation: "/api/hotel-cancel",
  storeFavourite: "/api/favourites/hotels",
  getFavouriteHotels: "/api/favourites/hotels",
  guestBooking: "/api/guest-reservations",
  deviceTokenRegister: "/api/check-version",

  notifications: {
    getNotifications: "/api/notifications",
    readNotifications: "/api/read-notifications",
  },
  appSettings: {
    getSettings: "/api/settings",
  },
  search: {
    getRecentSearches: "/api/recent-search",
    storeRecentSearch: "/api/recent-search",
    autocomplete: `${process.env.NEXT_PUBLIC_SEARCH_URL}/autocomplete`,
  },

  staticPages: {
    getAboutUs: "/api/about_us",
    getTerms: "/api/terms_and_conditions",
    getPrivacy: "/api/privacy_policies",
    getFaq: "/api/faqs",
    getContactUs: "/api/contact_us",
    getHowItWorks: "/api/how_it_works",
  },

  user: {
    updateUser: "/api/update-profile",
    changePassword: "/api/change-password",
    resetPassword: "/api/reset-password",
    getSavedCards: "/api/users/cards",
    activateUser: "/api/activate",
    resendCode: "/api/activate/resend",
    socialLogin: "/api/social-login",
    verifyUser: "/api/unique-identifier",
    resendActivationLink: "/api/activate/resend",
    getMembershipData: "/api/members_ship_settings",
  },
};
